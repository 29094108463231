<template>
	<el-dialog title="新增" v-model="performanceinfo" width="70%" center @close='closeDialog'>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
			<el-form-item label="标题" prop="title" :rules="[
      { required: true, message: '请输入名称', trigger: 'blur' },
    ]">
				<el-input v-model="ruleForm.title"></el-input>
			</el-form-item>

			<el-form-item label="Banner图片">
				<div>
					<img id="img" border="11" v-for="item in uploadFiles" v-bind:key="item"
						style="  margin: 10px;  padding: 3px; width:100px;height:100px;border:solid 1px #6a7990; border-radius:10%"
						:src="item.url" @click="delid(item)" />
				</div>
			</el-form-item>
			<el-form-item label="">
				<input id="filePhoto" class="select" ref="imgInput" type="file" accept="image/*"
					@change="uploadImg($event)">
			</el-form-item>


			<el-form-item label="指定可见项目" prop="title">
				<el-select @change="plotIdChange" v-model="ruleForm.article" placeholder="请选择" style="width:100%"
					multiple>
					<el-option v-for="data in optionsype" :key="data.plot_id" :label="data.plot_name"
						:value="data.plot_id">
					</el-option>
				</el-select>
			</el-form-item>


			<el-form-item label="内容" prop="name">
				<div style="border: 1px solid #ccc">
					<Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig"
						:mode="mode" />
					<Editor style="height: 500px; overflow-y: hidden;" v-model="valueHtml" :defaultConfig="editorConfig"
						:mode="mode" @onCreated="handleCreated" />
				</div>
			</el-form-item>
		</el-form>
		<div class="ucForm-group-ad">
			<div class="ucForm-group ucBtn-bg">
				<button class="ucBtn ucBtn-ok ucBtn-blue" v-on:click="commit('ruleForm')"><i
						class="iImg"></i>确定</button>
				<button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closeDialog"><i class="iImg"></i>返回</button>
			</div>
		</div>
		<div class="clear"></div>

	</el-dialog>

</template>

<script>
	//import '@wangeditor/editor/dist/css/style.css' // 引入 css
	import {
		onBeforeUnmount,
		ref,
		shallowRef,
		onMounted
	} from 'vue'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	export default {
		created() {
			this.performanceinfo = true;
			this.clearinfo();
			this.getplotname();
		},
		data() {
			let self = this;
			return {
				uploadFiles: [],
				ruleForm2: {},
				ruleForm: {
					id: '',
					status: '',
					name: '',
					optionsype: []
				}
			}
		},
		methods: {
			getplotname: function() {
				var self = this;
				self.options;
				var redata = {};
				redata.cmd = "getPmsName";
				this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas) {
					this.optionsype = [];
					for (var i = 0; i < datas.datas.items.length; i++) {
						var obj = {};
						obj.plot_id = datas.datas.items[i].plot_id + "";
						obj.plot_name = datas.datas.items[i].plot_name + "";
						this.optionsype.push(obj);
					}
				}, function(data) {
					self.$message(data.reason);
				}, self);
			},
			/*转换程base64上传后台图片的方法*/
			uploadImg: function(e) {
				if (this.uploadFiles.length > 5) {
					return;
				}
				var filename = document.getElementById('filePhoto').files[0].name
				var req = {};
				req.cmd = 'uploadPictures';

				let file = e.target.files[0];

				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (e) => {
					//为img对应的集合赋值
					let url = e.target.result;


					url = url.split(',');
					req.fileUrl = url[1];
					req.filename = filename;

					this.upload(req);
				};
			},

			upload: function(req) {
				let self = this;
				this.$sknet.postJson(this.$skconf("met.surl"), req, function(data) {

					var fileUrloss = 'https://pms.xhjr.tech/oss/file/' + data.datas.items.fileUrl;
					var url = fileUrloss;
					var car = {
						url: url
					}
					this.uploadFiles = [];
					this.uploadFiles.push(car)
					self.ruleForm2.picture = fileUrloss;
				}, function(data) {
					self.$message(data.reason);
				}, self);
			},
			delid: function(item) {
				this.uploadFiles = [];
				this.ruleForm2.picture = "";
			},
			commit: function(ruleForm) {

				console.log(1);
				this.$refs[ruleForm].validate((valid) => {

					if (valid) {
						var self = this;
						var data = {};
						data.cmd = 'cms_addContent';
						data.images = this.ruleForm2.picture;
						data.title = this.ruleForm.title;
						data.content = this.valueHtml;
						data.topicId = 1;
						let depts = "";
						for (let i = 0; i < this.ruleForm.article.length; i++) {
							if (i == 0) {
								depts += this.ruleForm.article[i];
							} else {
								depts += "," + this.ruleForm.article[i];
							}
						}
						data.deptIds = depts;
						this.$sknet.postJson(this.$skconf("met.cmsurl"), data, function(data) {
							self.$message("新增成功");
							self.closeDialog();
						}, function(data) {
							self.$message(data.reason);
						}, self);
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			closeDialog: function() {
				this.performanceinfo = false;
				this.clearinfo();
				this.$emit('closeClass'); //通知父组件改变。
			},
			clearinfo: function() {
				this.description = "";
			},

		},
		mounted() {

		},
		components: {
			Editor,
			Toolbar
		},
		setup() {
			// 编辑器实例，必须用 shallowRef
			const editorRef = shallowRef()

			// 内容 HTML
			const valueHtml = ref('')

			// 模拟 ajax 异步获取内容
			onMounted(() => {

			})

			const toolbarConfig = {}
			const editorConfig = {
				MENU_CONF: {},
				placeholder: '请输入内容...'
			}

			// 组件销毁时，也及时销毁编辑器
			onBeforeUnmount(() => {
				const editor = editorRef.value
				if (editor == null) return
				editor.destroy()
			})

			const handleCreated = (editor) => {
				editorRef.value = editor // 记录 editor 实例，重要！
			}

			return {
				editorRef,
				valueHtml,
				mode: 'default', // 或 'simple'
				toolbarConfig,
				editorConfig,
				handleCreated
			};
		}
	}
</script>

<style scoped>

</style>
