<template>
  <el-form ref="searchForm"   label-width="240" :inline="true">
    <el-form-item ><el-button   @click="addition()">新增Banner</el-button></el-form-item>
   
  </el-form>
  <el-table :data="dataList" border  ref="multipleTable"  @selection-change="handleSelectionChange">
    <el-table-column  type="selection"  align="center"  width="55"></el-table-column>
    <el-table-column prop="title" label="标题" ></el-table-column>
    <el-table-column prop="publishTime" label="发布时间"></el-table-column>
    <el-table-column fixed="right" label="操作" width="200" >
      <template v-slot="scope">
        <el-button  icon="el-icon-edit"    @click="Modify(scope.row)" type="success" size="small">编辑</el-button>
        <el-button  icon="el-icon-delete"   @click="Deleteinfo(scope.row)" type="danger" size="small">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-config-provider :locale="locale">
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pagesize"
        layout="total,sizes,prev,pager,next,jmper"
        :total="total">
    </el-pagination>
  </el-config-provider>

  <um_performanceinfo_list @closeClass="closeClass"  v-if="performanceinfoDialogVisible"></um_performanceinfo_list>
  <um_performance_add @closeClass="closeperformance"  v-if="performanceAddDialogVisible"></um_performance_add>


</template>
<script>
import  um_performanceinfo_list from './banner_update'
import  um_performance_add from './banner_add'
//引入vue方法
import { ElConfigProvider } from 'element-plus';
//中文包
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {

  name: 'ZhProvider',

  setup(){
    let locale = zhCn
    return{
      locale
    }
  },
  data() {
    let self = this;
    return{
      dataList:'',
      tableDataName:'',
      status:'',
      performanceinfoDialogVisible: false,
      performanceAddDialogVisible:false,
      total:0,          //总页数
      pagesize:10,        //每页数量
      currentPage:1,    //初始页
      checkedGh: [],    //复选框默认值
      paramsid:'',
      paramsname:'',
    }
  },
  /*启动后触发该方法*/
  created() {
    this.handleClickQuery();
  },
  /*方法主体*/
  methods: {
    handleClickQuery:function(data){
      this.dataList = [];
      var req = {};
      req.cmd = "cmsShow_searchContents";
	  req.divNumber=1;
	  req.topicId=1;
      req.start=(this.currentPage-1) * this.pagesize;
      req.count=this.pagesize;
      this.$sknet.postJson(this.$skconf("cla.cmsurl"), req, function(res) {

        this.dataList=res.datas.items;
        this.total=res.datas.totalCount;
      }, null, this);
    },
    //重置查询条件
    reset:function(){
      this.tableDataName='';
      this.queryMembers();
    },
    //条件查询的方法
    queryMembers:function(){
      this.dataList = [];
      var req = {};
      req.cmd = "cmsShow_searchContents";
      req.divNumber=1;
      req.topicId=1;
      req.start=(this.currentPage-1) * this.pagesize;
      req.count=this.pagesize;
      this.$sknet.postJson(this.$skconf("cla.cmsurl"), req, function(res) {
      
        this.dataList=res.datas.items;
        this.total=res.datas.totalCount;
      }, null, this);
    },
    /*删除的方法*/
    Deleteinfo:function(item){
      this.$confirm('是否确认删除。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.updeteclass(item);
      }).catch(() => {});
    },
    updeteclass:function(item){
      var self = this;
      var data = {};
      data.cmd = "cms_removeContent";
      data.id=item.id,
      this.$sknet.postJson(this.$skconf("cla.cmsurl"), data, function(data){
            self.$message("删除成功");
            this.handleClickQuery();
          }, function(data) {
      }, self);
    },
    /*编辑跳转的方法*/
    Modify: function(item) {

      this.id = item.id+"";
      this.title = item.title+"";
      this.content = item.content+"";
	  this.images = item.images+"";
	  this.depts = item.deptIds+"";
      this.performanceinfoDialogVisible = true;
    },
    closeClass:function(){
      this.id = '';
      this.mod = '';
      this.performanceinfoDialogVisible = false;
      this.handleClickQuery();
    },
    /*新增跳转的方法*/
    addition:function(){
      this.id = '';
      this.mod = '';
      this.performanceAddDialogVisible = true;
    },
    closeperformance:function(){
      this.performanceAddDialogVisible = false;
      this.handleClickQuery();
    },

    /*二级绩效的跳转方法*/
    fetchData: function(){
      var req = {};
      if(this.paramsid==null||this.paramsid==''){
        this.$message({
          message: '请选择一条信息',
          type: 'warning'
        });
      }
      else{
        req.father_id=this.paramsid;
        req.father_name=this.paramsname;
        this.$router.push({name:'sec_performance_list',params:req});
      }

    },



    /*分页代码*/
    handleSizeChange: function (size) {
      this.pagesize = size;
      console.log(this.pagesize)  //每页下拉显示数据

    },
    handleCurrentChange: function(currentPage){
      this.currentPage = currentPage;
      this.handleClickQuery();
      console.log(this.currentPage)  //点击第几页
    },

    forstusta:function(row,colum){
      const state={
        0:'禁用',
        1:'开启',
      }
      return  state[row.status];
    },

    // 单选框选中数据
    handleSelectionChange:function(selection) {
      if(selection.length ==0){
        this.paramsid='';
      }
      else if(selection.length ==1){
        this.paramsid=selection[0].id;
        this.paramsname=selection[0].name;
      }
      else if(selection.length >1){
        this.$refs.multipleTable.clearSelection();
        this.$refs.multipleTable.toggleRowSelection(selection.pop());
      }
    },
    /*table界面按钮的方法*/
    handleIsDisplay:function($index,row){
     
    },

  },

  components: {
    um_performanceinfo_list,
    um_performance_add,
    [ElConfigProvider.name]: ElConfigProvider,
  },

}
</script>
<style>

</style>